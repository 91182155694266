//Ionic & React
import React, {useEffect, useState} from "react";
//Components
import {
  MapButtons,
  TGMap,
  TGPage,
  Modals,
  ActiveSessionButton,
} from "../components";
//Helpers
import {getActiveSession, getNotifications, getUserOccupation, isUserLoggedIn} from "../helpers";
//Redux
import {useIonViewDidEnter} from "@ionic/react";
import MapBottomButtons from "../components/MapBottomButtons";
import {GlobalFirstTimeModal} from "../components/GlobalFirstTimeModal";
import history from "../helpers/history";

const Map = () => {
  const [map, setMap] = useState({});

  useIonViewDidEnter(() => {

    if (isUserLoggedIn()){
      getActiveSession(false)
      getNotifications()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (isUserLoggedIn()){
        getUserOccupation()
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <TGPage
      scrollY={false}
      fullscreen={true}
      ionPadding={false}
      toolbar={false}
      cssClass={"TGMapPageContent"}
    >
      <TGMap map={map} setMap={setMap}/>
      <MapButtons map={map}/>
      <Modals map={map}/>
      <ActiveSessionButton />
      <MapBottomButtons />
      <GlobalFirstTimeModal/>
    </TGPage>
  );
};

export default Map;
