//Ionic & React
import React, {memo, useEffect} from "react"
import {IonRouterOutlet, iosTransitionAnimation, mdTransitionAnimation} from "@ionic/react";
//Router
import {IonReactRouter} from "@ionic/react-router";
import {Redirect, Route} from "react-router-dom";
//Components & Pages
import {NotFound, VehiclePreview} from "./components"
import {
    Map,
    RegisterPhone,
    MyProfile,
    ConfirmPhone,
    Onboarding,
    EditUser,
    TermsConditions,
    About,
    PrivacyPolicy,
    Statistics,
    License,
    Settings,
    Payments,
    Vehicles,
    ChangeLanguage,
    ManagePermissions,
    AddVehicles,
    EnterPlate,
    RegisteredCards,
    Invoices,
    ActiveSession,
    Notifications,
    AddPaymentMethod,
    InvoiceDetail,
    Receipt,
    StartSession,
    Corporate,
    Pricing,
    FAQPage,
    Agreements,
    ContactUs,
    Support,
    Tickets,
    TicketDetail,
    Wallet,
    ChargingHistory,
    AddSubscription,
    TicketForParking
} from "./pages";
//Helpers
import {checkLocalStorage, env} from "./helpers";
import AppUrlListener from "../src/AppUrlListener";
//Redux
import {useSelector} from "react-redux";
import Campaign from "./pages/Campaign";
import AddBalance from "./pages/AddBalance";
import BalanceAdded from "./pages/BalanceAdded";
import Membership from "./pages/Membership";
import MembershipList from "./pages/MembershipList";
import AboutGrouped from "./pages/AboutGrouped";
import {Menu} from "./components/Menu";
import {Scanner} from "./components/Scanner";
import {Capacitor} from "@capacitor/core";
import history from "./helpers/history";


const customIosTransitionAnimation = (baseEl, opts) => {
    const animation = iosTransitionAnimation(baseEl, opts);
    animation.duration(350);
    return animation;
};

const customMdTransitionAnimation = (baseEl, opts) => {
    const animation = mdTransitionAnimation(baseEl, opts);
    animation.duration(350);
    return animation;
};

const GuardedRoute = ({component:Component, ...rest}) => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}

    return (
      <Route
        {...rest}
        render={(props) =>
          user.token ? <Component {...props} /> : <Redirect to="/register-phone" />
        }
      />
    );
}

const Routes = () => {
    let user_info = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}
    const {user} = useSelector(state => state.user)
    const ios = Capacitor.getPlatform() === "ios";
    const {isFirst} = user;

    useEffect(() => {
        checkLocalStorage('user');
    }, [])

    const initRouteRenderMethod = () => {
        if (user_info.id) {
            return <Redirect to={"/map"}/>;
        }

        if (isFirst && env("ONBOARDING_ENABLED") === "true") {
          return <Redirect to={"/onboarding"} />;
        }
        return <Redirect to={"/register-phone"}/>;
    };


    return (
        <IonReactRouter history={history}>
            <AppUrlListener/>
            <IonRouterOutlet animation={ios ? customIosTransitionAnimation : customMdTransitionAnimation}>
                <Route path="/" render={initRouteRenderMethod} exact/>
                <Route path="/map" component={Map} exact/>
                <Route path="/campaign" component={Campaign} exact/>
                <Route path="/onboarding" component={Onboarding} exact/>
                <Route path="/register-phone" component={RegisterPhone} exact/>
                <Route path="/register-phone/confirm" component={ConfirmPhone} exact/>

                <GuardedRoute path="/start-session" component={StartSession} exact />
                <GuardedRoute path="/scanner" component={Scanner} exact />
                <GuardedRoute path="/active-session" component={ActiveSession} exact />
                <GuardedRoute path="/receipt" component={Receipt} exact />

                <Route path="/menu" component={Menu} exact/>
                <GuardedRoute path="/menu/edit-user" component={EditUser} exact/>
                <GuardedRoute path="/menu/notifications" component={Notifications} exact/>
                {/*<GuardedRoute path="/menu/membership" component={TicketForParking} exact/>*/}
                <GuardedRoute path="/menu/membership/list" component={MembershipList} exact/>
                <GuardedRoute path="/menu/membership/my-subscription/:id" component={Membership} exact/>
                <GuardedRoute path="/menu/membership/add-subscription" component={AddSubscription} exact/>

                <GuardedRoute path="/menu/wallet" component={Wallet} exact/>
                <GuardedRoute path="/menu/wallet/add-balance" component={AddBalance} exact/>
                <GuardedRoute path="/menu/wallet/balance-added" component={BalanceAdded} exact/>

                <GuardedRoute path="/menu/vehicles" component={Vehicles} exact/>
                <GuardedRoute path="/menu/vehicles/add-vehicle" component={AddVehicles} exact/>
                <GuardedRoute path="/menu/vehicles/enter-plate" component={EnterPlate} exact/>
                <GuardedRoute path="/menu/vehicles/enter-plate/vehicle-preview" component={VehiclePreview} exact/>

                <GuardedRoute path="/menu/payments" component={Payments} exact/>
                <GuardedRoute path="/menu/payments/invoices" component={Invoices} exact/>
                <GuardedRoute path="/menu/payments/invoices/detail/:id" component={InvoiceDetail} exact/>
                <GuardedRoute path="/menu/payments/registered-cards" component={RegisteredCards} exact/>
                <GuardedRoute path="/menu/payments/add-payment-method" component={AddPaymentMethod} exact/>
                <GuardedRoute path="/menu/payments/corporate-account" component={Corporate} exact/>
                <GuardedRoute path="/menu/payments/charging-history" component={ChargingHistory} exact />


                <Route path="/menu/about-grouped" component={AboutGrouped} exact/>
                <Route path="/menu/about-grouped/about" component={About} exact/>
                <Route path="/menu/about-grouped/agreements" component={Agreements} exact/>
                <Route path="/menu/about-grouped/agreements/license" component={License} exact/>
                <Route path="/menu/about-grouped/agreements/privacy-and-policy" component={PrivacyPolicy} exact/>
                <Route path="/menu/about-grouped/agreements/terms-and-conditions" component={TermsConditions} exact/>

                <Route path="/menu/pricing" component={Pricing} exact/>

                <Route path="/menu/settings" component={Settings} exact/>
                <Route path="/menu/settings/change-language" component={ChangeLanguage} exact/>
                <Route path="/menu/settings/manage-permissions" component={ManagePermissions} exact/>

                <Route path="/menu/support" component={Support} exact/>
                <Route path="/menu/support/faq" component={FAQPage} exact/>
                <GuardedRoute path="/menu/support/send-ticket" component={ContactUs} exact/>
                <GuardedRoute path="/menu/support/tickets" component={Tickets} exact/>
                <GuardedRoute path="/menu/support/tickets/:id" component={TicketDetail} exact/>

                <Route component={NotFound} exact/>

                {/* These routes are not ready yet */}
                {/*<Route path="/menu/my-profile" component={MyProfile} exact/>*/}
                {/*<Route path="/statistics" component={Statistics} exact />*/}
            </IonRouterOutlet>
        </IonReactRouter>
    );
};

export default memo(Routes);
