import React, {useEffect} from 'react';
import {IonToast, useIonViewDidEnter, useIonViewWillLeave} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";
import {setOccupation} from "../../redux/slices/userSlice";
import {getUserOccupation, isUserLoggedIn} from "../../helpers";
import './OccupationNotificationStyle.css';

const OccupationNotification = () => {
    const {occupation} = useSelector(state => state.user);
    const {isOpen, occupation_time} = occupation ?? {}
    const {mailVerificationToast} = useSelector(state => state.global)
    const [seconds, setSeconds] = React.useState((occupation_time*60) ?? 0);
    const dispatch = useDispatch();
    const closeToast = () => dispatch(setOccupation({isOpen: false}));

    useIonViewDidEnter(()=>{
        if (isUserLoggedIn()) {
            getUserOccupation()
        }
    },[]);

    useIonViewWillLeave(() => {
        closeToast();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (setSeconds) {
                setSeconds(prevSeconds => prevSeconds + 1)
            }
        },1000);

        return () => clearInterval(interval);

    }, [setSeconds]);

    const formatSeconds = seconds => {
        const pad = n => n<10 ? '0'+n : n;

        const h = Math.floor(seconds/3600);
        const m = Math.floor((seconds/60)-(h*60));
        const s = Math.floor(seconds - h * 3600 - m * 60);

        return `${pad(h)}:${pad(m)}:${pad(s)}`;
    }


    return (
        <IonToast
            isOpen={isOpen}
            position="top"
            header={`${t('occupied-text')} ${formatSeconds(seconds)} ${t('occupied-text-2')}`}
            swipeGesture={"vertical"}
            onDidDismiss={closeToast}
            className={`custom-toast-occupation ${mailVerificationToast ? 'mail': ''}`}
        />
    );
};

export default OccupationNotification;