import React from 'react';
import {TGPage, TGText} from "../components";
import {useTranslation} from "react-i18next";
import {IonItem, IonLabel, IonList, IonNote, useIonViewWillEnter} from "@ionic/react";
import {api} from "../helpers";
import {setAllParks} from "../redux/slices/membershipSlice";
import {useDispatch, useSelector} from "react-redux";
import parkListIcon from '../assets/park_list_icon.png'
import parkChargeListIcon from '../assets/park_charge_list_icon.png'

const MembershipList = () => {
    const {allParks} = useSelector(state => state.membership);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useIonViewWillEnter(() => {
            api.getParkList(false).then((r) => {
                r.data?.data && dispatch(setAllParks(r.data.data))
            });
        }
    )

    return (
        <TGPage
            title={t('parking-list')}
            scrollY={true}
            backButton={true}
            cssClass={"MembershipList"}
        >

            <TGText className={"parkListTitle"}> {t('select-parking')} </TGText>
            <IonList className={"parkList"}>
                {allParks.map(park => {
                    const href = `/menu/membership/my-subscription/${park.id}`;
                    return (
                        <IonItem routerLink={href} className={"parkListItem"}>
                            <div slot="start" className={"parkListIcon"}>
                                <img src={park.numberOfEvSlots > 0 ? parkChargeListIcon : parkListIcon} alt={park.name}/>
                            </div>
                            <IonLabel>{park.name}</IonLabel>
                            {park.numberOfUserSubscriptions > 0 && (
                                <div slot="end" className={"parkListCount"}>
                                    {park.numberOfUserSubscriptions}
                                </div>
                            )}
                        </IonItem>
                    );
                })}
            </IonList>
        </TGPage>
    );
};

export default MembershipList;