import {IonSelect, IonSelectOption} from "@ionic/react";
import {t} from "i18next";
import React from "react";
import {useSelector} from "react-redux";
import {TGText} from "../TGElements";

const ParkListDropdown = () => {
    const {selectedPark,allParks} = useSelector(state => state.membership)

    return (
        <div className={"ParkListDropdownWrapper"}>
            <TGText
                fontSize={16}
                fontWeight={500}
                margin={'0 0 10px 0'}
            >
                {t("choose-park-point")}
            </TGText>

            <IonSelect className={`ParkListDropdown ${selectedPark !== "" && "active"}`} value={selectedPark} disabled>
                {allParks && allParks.map((park, key) => (
                    <IonSelectOption key={key} value={park.id}>{park.name}</IonSelectOption>
                ))}
            </IonSelect>
        </div>
    );
};

export default ParkListDropdown;
