import {setLoading} from "../redux/slices/globalSlice";
import {setCheckUserModal, setSearchModal, setStationDetailModalOpen,} from "../redux/slices/modalSlice";
import {setActiveSession} from "../redux/slices/sessionSlice";
import {setCurrentStation} from "../redux/slices/stationSlice";
import {
    setBalance,
    setNotifications,
    setOccupation,
    setPaymentMethods,
    setUser,
    setVehicles
} from "../redux/slices/userSlice";
import {setStories, setStoryPreviews} from "../redux/slices/storiesSlice";
import {store} from "../redux/store";
import {fetch} from "./CapacitorFetch";
import {Alert} from "./alert";
import Api, {api} from "./api";
import {redirectUrl} from "./globalFunctions";
import {t} from "i18next"
import {setParkDetail, setParkDetailModal} from "../redux/slices/parkSlice";
import {setMembershipDetail, setUserSubscriptions} from "../redux/slices/membershipSlice";
import history from "./history";

export const registerPhone = async (phone) => {
    const result = await api.registerPhone(phone)
    if (result?.status !== 200) {
        Alert.error(t("invalid-phone-number"))
        return {success: false}
    }
    if (result?.data?.success) {
        return {success: true}
    } else {
        Alert.warning(result?.data?.message);
        return {success: false}
    }
}

const mapFilters = () => {
    const {mapFilter} = store.getState().map
    return {
        "is_parking": mapFilter === "park" ? "true" : "false",
        "is_charging": mapFilter === "charge" ? "true" : "false",
        "is_park_and_charge": mapFilter === "park-charge" ? "true" : "false",
    }
}

export const getStations = async (lat, lng, searchText = "") => {
    const {stationFilters} = store.getState().search

    const filters = {
        ...mapFilters(),
        ...stationFilters,
        keyword: searchText
    }
    const response = await api.getStations(lat, lng, filters, false);

    if (response.status === 200) {
        return response.data.response || [];
    }

    return [];
}


/**
 *
 * @param station // is station id for getStationDetail,
 * @param openModal // is boolean for open station detail modal
 * @param from // for triggered from where
 * @returns {Promise<null | {}>} // return station detail or null
 *
 */
export const getStationDetail = async (station, openModal, from = "map") => {
    let stationDetail;
    const {dispatch, getState} = store
    const searchModal = getState().modal.searchModal.status


    stationDetail = await api.getStationDetail(station.id);

    if (stationDetail.status === 200 && stationDetail.data.response) {
        dispatch(setCurrentStation({...stationDetail.data.response, FROM: from, DETAIL: stationDetail.data.response}));

        if (openModal) {
            searchModal && dispatch(setSearchModal(false))
            dispatch(setStationDetailModalOpen(true));
        }
    }
}

export const getParkDetail = async (park, openModal) => {
    const {dispatch} = store

    const parkDetail = await api.getParkDetail(park.id);

    if (parkDetail.status === 200 && parkDetail.data.data) {
        dispatch(setParkDetail(parkDetail.data.data))

        if (openModal) {
            dispatch(setParkDetailModal(true))
        }
    }
}

export const getVehicles = () => {
    api.getVehicles(false).then((response) => {
        if (response.status === 200) {
            store.dispatch(setVehicles(response.data.data || []));
        }
    });
};
export const getVehicleByPlate = async (plate) => {
    return await api.getVehicleByPlate(plate).then((response) => {
        if (response.status === 200) {
            if (response.data.status !== false && response.data.data[0])
                return response.data.data[0];
        }
    });
};

export const saveMyCar = async (vehicle) => {
    const response = await api.saveCar(vehicle)

    if (response.data.success) {
        getVehicles()
    }

    return response.data.success
};
export const setDefaultVehicle = async (id) => {
    api.setDefaultVehicle(id).then((response) => {
        if (response.data.success) {
            getVehicles();
            return response.data.success
        }
    });
};

export const isUserAbleToStartSession = async () => {
    const {dispatch} = store
    const user = store.getState().user
    const response = await api.checkUserAbleToStartSession()
    const res = response?.data?.response;

    // vehicle not found
    if (res && !res.vehicle) {
        dispatch(setCheckUserModal({debt: false, vehicle: true, paymentMethod: false}))
        return;
    }

    // payment method not found
    if (res && !res.paymentMethod) {
        dispatch(setCheckUserModal({debt: false, vehicle: false, paymentMethod: true}))
        return;
    }

    // debt found
    if (res && !res.debt) {
        if (res.dept_type === "wallet") {
            dispatch(setCheckUserModal({
                wallet_dept: res.dept_type,
                debt: false,
                vehicle: false,
                paymentMethod: false,
            }))

        } else {
            dispatch(setCheckUserModal({debt: true, wallet_dept: res.dept_type, vehicle: false, paymentMethod: false}))
        }
        return;
    }

    if (response?.status === 200 && response?.data?.success) {
        return {response: response?.data?.response}
    }
}

export const startCharge = async (code) => {
    const apiService = new Api(90000)
    const {vehicles} = store.getState().user
    const vehicle = vehicles.find(v => v.is_default === 1)

    if (!vehicle) {
        Alert.error(t("no-default-vehicle"))
        return {start: false}
    }

    const isReady = await isUserAbleToStartSession()
    if (!isReady) return {start: false}

    const response = await apiService.startCharge(vehicle.id, code)

    if (response?.data?.success) {
        return {start: true, isCableConnected: response.data?.data?.isCableConnected}
    }
    if (response?.data?.message) {
        Alert.error(response.data.message)
        return {start: false}
    }

    Alert.error(t("cant-start-session"))
    return {start: false}
};

export const stopCharge = async () => {
    const apiService = new Api(90000)
    const {activeSession} = store.getState().session
    const response = await apiService.stopCharge(activeSession.id)
    if (response.data.success) {
        store.dispatch(setActiveSession({}));
        return {stop: true, response: response.data.data}
    }
    return {stop: false}
}
export const updateProfilePicture = async (files) => {
    return api.editProfilePicture(files)
}
export const getUser = async (isLoading = true) => {
    const response = await api.getUser(isLoading);
    if (response.status === 200) {
        store.dispatch(setUser(response.data.data[0]));
    }
    return response
};
export const editUser = async (userData) => {
    return api.editUser({"options": {"parameters": userData}}).then(response => {
        if (response.data.success) {
            getUser(false)
            return response
        }
    })
}
export const getVehicleBrands = async () => {
    const response = await api.getVehicleBrandList();
    if (response.status === 200) {
        return response.data.data;
    }
}
export const getVehicleModels = async (brandId) => {
    const response = await api.getVehicleModelsList(brandId);
    if (response.status === 200) {
        return response.data.data;
    }
}

export const getContent = async (slug) => {
    const result = await api.getPages(slug)
    if (result.status !== 200 || result.data.success !== true || !result.data.response) return;
    return result.data.response.content
}

export const editVehicle = async () => {
    const {newPlate, brand, model} = store.getState().newCar;
    const res = await api.editVehicle(newPlate, brand.id, model.id)
    if (res.status !== 200 || res.data.success !== true) return;
    else {
        Alert.success(res.data.response.message)
        return res.data.success
    }
}
export const getInvoiceList = async (isLoading, filters, page = 1) => {
    const result = await api.getInvoices(isLoading, filters, page) // api request
    if (result.status !== 200) {
        return [];
    }
    const invoices = result?.data?.data ?? []
    return {lastPage: result?.data?.pagination?.last_page, invoices} // return last_page to handle pagination
}

export const getInvoiceFilters = async () => {
    const res = await api.getInvoiceFilters()
    if (res.status !== 200 || !res.data.data[0]) {
        return {success: false}
    }
    return {success: true, filters: res.data.data[0]}
}

export const getInvoiceDetail = async (id) => {
    const result = await api.getInvoiceDetail(id)
    if (result.status !== 200 || !result.data.success) return;
    return result.data.response
};

export const removeVehicle = async (plate) => {
    const result = await api.removeVehicle(plate)
    if (result.status !== 200 || result.data.success !== true) return;
    getVehicles()
    return result.data.success
}

export const getActiveSession = async (loading) => {
    const {currentPath} = store.getState().global.route
    const isLoading = loading !== undefined ? loading : currentPath !== "map"
    isLoading && store.dispatch(setLoading(true))
    const response = await fetch("post", "function/charging/transactions/activeCharge")
    if (response.status !== 200 || !response?.data?.success) return;
    store.dispatch(setActiveSession(response.data.response))
    isLoading && store.dispatch(setLoading(false))
    return response.data.response
}

export const getNotifications = async () => {
    const res = await api.getNotifications()
    if (!res || res.status !== 200) return;
    const result = Object.entries(res.data.data);
    store.dispatch(setNotifications(result))
}
export const createCard = async (email, card) => {
    const res = await api.createNewCard(email, card)
    if (res.status !== 200 || res.data.success !== true) {
        return {success: false, message: res.data.message};
    }
    return {success: true}
}

export const getPaymentMethods = async () => {
    const res = await api.getPaymentMethods()
    if (res.status === 200) {
        const paymentMethods = res.data?.data
        store.dispatch(setPaymentMethods(paymentMethods))
    }
}

export const removeCreditCard = async (card) => {
    const res = await api.removeCard(card)
    if (res.status !== 200 || !res.data.success) {
        Alert.error("try-again")
        return;
    } else {
        getPaymentMethods()
        return res.data
    }
}

export const setDefaultCard = async (card) => {
    const res = await api.setDefaultCard(card)
    if (res.status !== 200 || !res.data.success) {
        Alert.error("try-again")
        return;
    } else {
        getPaymentMethods()
        return {success: true}
    }
}
export const deleteNotification = async (id) => {
    const res = id ? await api.deleteNotification(id) : await api.deleteAllNotifications()
    if (res.status !== 200 || !res.data.success) return;
    getNotifications()
}
export const setNotificationRead = async id => {
    const res = await api.markAsReadById(id)
    if (res.status !== 200 || !res.data.success) return;
    getNotifications()
    return {success: true}
}

export const createCompany = async (company) => {
    const response = await api.createCompany(company);
    if (response.status === 200) {
        return response.data;
    }
}

export const getMyCompanies = async (isLoading) => {
    const response = await api.getMyCompanies(isLoading);
    if (response.status === 200) {
        return response.data;
    }
}

export const deleteCompany = async (id) => {
    const response = await api.removeCompany(id);
    if (response.status === 200) {
        return response.data;
    }
}

export const transactionUpdate = async (id, company_id) => {
    const response = await api.transactionCompanyUpdate(id, company_id);
    if (response.status === 200) {
        return response.data;
    }
}

export const sendContactForm = async data => {
    const response = await api.postContactForm(data)
    if (response.status === 200 || response.data.success) {
        Alert.success(response.data.message)
        return {success: true}
    } else {
        Alert.error(t("try-again"))
        return {success: false}
    }
}

export const getTickets = async () => {
    const response = await api.ticketList()
    if (response.data?.success) {
        return response.data.data.data
    }
    Alert.error(response.data.message)
}

export const getTicketDetails = async (id) => {
    const response = await api.ticketDetails(id)
    if (response.data?.success) {
        return response.data.data
    }
    Alert.error(response.data.message)
}

export const sendTicketAnswer = async (ticketId, content, file) => {
    const response = await api.postAnswer(ticketId, content, file)
    if (response.status === 200 || response.data.success) {
        Alert.success(response.data.message)
        return {success: true}
    } else {
        Alert.error(t("try-again"))
        return {success: false}
    }
}
export const closeTicket = async (id) => {
    const response = await api.closeTicket(id)
    if (response.status === 200 || response.data.success) {
        Alert.success(response.data.message)
        return {success: true}
    } else {
        Alert.error(t("try-again"))
        return {success: false}
    }
}

export const getWalletBalance = () => {
    api.getWalletBalance().then(({data}) => {
        if (data.success) {
            store.dispatch(setBalance(data.data));
        }
    })
}

export const getStationDetailByCode = async (code) => {
    const response = await api.getStationDetailByCode(code)
    if (response.data.data) {
        return response.data.data
    }
    return false
}

export const resendVerificationMail = async () => {
    const response = await api.resendMail()
    if (response.status === 200) {
        Alert.success(response.data.response.message)
        return {success: true}
    }
    return {success: false}
}

export const getTicketsForParking = async () => {
    const response = await api.ticketListForParking()
    if (response.data?.success) {
        return response.data.data.data
    }
    Alert.error(response.data.message)
}


export const getParks = async (lat, lng) => {
    const response = await api.getParks(lat, lng, false);

    if (response.status === 200) {
        return response.data.data || [];
    }

    return [];
}

export const getStories = async () => {
    const newApi = new Api(31000, false)
    const response = await newApi.getStories()
    const dispatch = store.dispatch

    const seeMoreClickHandler = (items) => {
        if (items[0].link) redirectUrl(items[0].link)
    }

    if (response.status === 200) {
        const { data } = response;
        if (data.length > 0) {
            dispatch(setStoryPreviews(data));

            const stories = data.flatMap(item => {
                const mapStory = (storyItem) => ({
                    id: storyItem.id,
                    url: storyItem.src,
                    header: {
                        heading: item.name,
                        img: item.photo,
                    },
                    seeMore: () => {},
                    seeMoreCollapsed: () => (
                        <div
                            onClick={() => seeMoreClickHandler(storyItem)}
                            className={`SeeMoreButtonWrapper ${!storyItem.link && 'passive'}`}
                        >
                            <div className="upside-arrow-icon"></div>
                            <div className="SeeMoreButton">
                                {storyItem.linkText}
                            </div>
                        </div>
                    ),
                });

                return item.items.map(mapStory)
            });

            dispatch(setStories(stories));
        }
    }


    return []
}

export const getUserSubscription = async (parkId) => {
    const dispatch = store.dispatch
    const response = await api.getUserSubscriptions(parkId)
    if (response.status === 200) {
        let data = response?.data.data;
        if (data) {
            data.userSubscriptions && dispatch(setUserSubscriptions(data.userSubscriptions))
            dispatch(setMembershipDetail({
                availableQuota: data.availableQuota,
                freeQuota: data.freeQuota,
                subscriptionCount: data.subscriptionCount,
                vehicleCount: data.vehicleCount
            }))
        }
    }
    return false
}

export const getUserOccupation = async () => {
    const dispatch = store.dispatch
    const response = await api.getOccupation()

    if (response.data.success && history.location.pathname === "/map") {
        const data = response.data.data
        const time = data.occupation_time
        dispatch(setOccupation({
            isOpen: true,
            occupation_time: time
        }))
        return
    }
    dispatch(setOccupation({
        isOpen: false,
        occupation_time: 0
    }))

}