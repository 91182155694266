import React, {useEffect, useRef} from 'react';
import {IonModal} from "@ionic/react";
import './GlobalFirstTime.css';
import {TGIcon} from "../TGElements";
import {getContent} from "../../helpers";
import {useSelector} from "react-redux";
import history from "../../helpers/history";

const GlobalFirstTimeModal = () => {
    const [open, setOpen] = React.useState(false);
    const {first_time_modal} = useSelector(state => state.config)
    const content = useRef(null)

    useEffect(() => {
        if (!first_time_modal || localStorage.getItem(first_time_modal)) return
        getContent(first_time_modal).then((result) => {
            if (result) {
                content.current = result
                setOpen(true)
            }
        })
    }, []);

    const close = () => {
        setOpen(false)
        localStorage.setItem(first_time_modal, true)
    }


    const handleContentClick = (e) => {
        if (e.target.tagName === 'BUTTON') {
            e.preventDefault()
            e.stopPropagation()
            close()
            const url = e.target.getAttribute('href')
            if (url.startsWith('http')) {
                return window.open(url, '_blank')
            }
            history.push(url)
        }
    }

    return (
        <IonModal id="first_time" isOpen={open} onDidDismiss={close}>
            <div className={"header"} onClick={close}>
                <TGIcon
                    name={"gray-close"}
                    width={30}
                    height={30}
                />
            </div>
            <div className={"content"} onClick={handleContentClick} dangerouslySetInnerHTML={{__html:content.current}}></div>
        </IonModal>
    );
};

export default GlobalFirstTimeModal;