import { createSlice } from "@reduxjs/toolkit"

export const configSlice = createSlice({
  name: "configSlice",
  initialState: {
    api_keys: {},
    company: {},
    live_server: {},
    is_qr: true,
    first_time_modal: null,
  },
  reducers: {
    setConfig: (state, action) => {
      if(state && action.payload) {
        state.api_keys = action.payload.api_keys
        state.company = action.payload.company
        state.live_server = action.payload.live_server
        state.is_qr = action.payload.is_qr
        state.first_time_modal = action.payload.first_time_modal
      }
    },
  },
})

export const { setConfig } = configSlice.actions
export default configSlice.reducer
